import React, { useState, useEffect } from "react";
import { list } from "../utils/api-journal";

export default function Home(props) {
  const [journals, setJournals] = useState([]);
  const [categories, SetCategories] = useState([]);

  useEffect(() => {
    document.title = "Gupta Publications";
    const abortController = new AbortController();
    const signal = abortController.signal;

    list(signal).then((data) => {
      if (data && data.error) {
        console.log(data.error);
      } else if (data) {
        setJournals(data.filter((journal) => journal.status === "enabled"));
        SetCategories([
          ...new Set(
            data
              .filter((journal) => journal.status === "enabled")
              .map((journal) => journal.category)
          ),
        ]);
      }
    });

    return function cleanup() {
      abortController.abort();
    };
  }, []);

  return (
    <>
      {categories.map((category, index) => {
        return (
          <>
            <div className="flex justify-center p-1 mt-2 bg-blue-B">
              <div className="px-4 py-1 text-xl text-white w-fit blinkBox">
                {category}
              </div>
            </div>
            <div className="flex flex-col gap-4 my-2">
              {journals
                .filter((journal) => journal.category === category)
                .map((journal, index) => {
                  return (
                    <div className="flex gap-4 text-base font-bold text-blue-A">
                      <div className="w-10 text-sm text-right text-red-600">
                        {index + 1}.
                      </div>
                      <div className="flex flex-col w-full gap-2 md:gap-4 md:flex-row">
                        <div className="md:w-[60%]">
                          <a href={`https://${journal.link}`} target="_blank">
                            {journal.name}
                          </a>
                        </div>
                        <div className="md:w-[40%]">
                          <a href={`https://${journal.link}`} target="_blank">
                            {journal.link}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        );
      })}
    </>
  );
}
