import React from "react";
import { Outlet, Link } from "react-router-dom";
import WhatsAppSticky from "./WhatsAppSticky";

export default function Root() {
  return (
    <>
      {/* <WhatsAppSticky /> */}
      <div className="max-w-screen-lg p-2">
        <div className="border-b border-gray-300">
          <img src="/public/images/page.jpg" alt="logo" />
          {/* <div className="flex items-center justify-center gap-4 p-4 font-bold text-blue-A">
            <img className="h-16 w-28" src="/public/images/logo.png" alt="logo" />
            <div className="flex flex-col gap-4 text-center">
              <div className="md:text-3xl">ACADEMIC PUBLICATIONS</div>
              <div className="md:text-xl">PUBLISH RESEARCH ARTICLE</div>
            </div>
          </div> */}
          {/* <div className="flex gap-[5px] text-center"> */}
          {/* <Link className="flex-1 p-2 text-lg text-white bg-blue-A" to="/">Home</Link> */}
          {/* <Link className="flex-1 p-2 text-lg text-white bg-blue-A" to="/payment">Payment</Link>
            <Link className="flex-1 p-2 text-lg text-white bg-blue-A" to="/contact">Contact</Link> */}
          {/* </div> */}
          {/* <img className="pt-2" src="/public/images/banner.jpg" /> */}
        </div>
        {/* <Outlet /> */}
        {/* <div className="flex flex-wrap gap-2 mt-4">
          <Link to="/privacypolicy">Privacy Policy</Link>
          <Link to="/refundpolicy">Refund Policy</Link>
          <Link to="/terms">Terms and Conditions</Link>
        </div> */}
        {/* <img src="/public/images/PaymentStripe.png" className="hidden mt-4 md:block" />
        <img src="/public/images/PstripeSmall.png" className="block mt-4 md:hidden" /> */}
        <div className="w-full pt-2 mt-4 text-center border-t border-gray-300">COPYRIGHT © 2023. ALL RIGHTS RESERVED.</div>
      </div>
    </>
  );
}